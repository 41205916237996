<template>
  <div class="bigbox">
    <div class="content">
      <div class="left">
        <div class="headerimg">
          <div class="block">
            <img v-if="user.headImage" :src="'/com/file/fileDownLoad?filePath=' + user.headImage" alt="" style="width:120x;height:120px"/>
            <img v-else src="../../assets/personalCenter/headerimg.png" alt="" style="width:120x;height:120px"/>
          </div>
          <div class="name">{{user.realName}}</div>
        </div>
        <div class="menu">
          <el-menu
            style="border-right: 0px;width:120px;"
            :default-active="active"
            @select="handleSelect"
          >
            <el-menu-item index="1">
              <i class="el-icon-menu"></i>
              <span slot="title">个人信息</span>
            </el-menu-item>
            <el-menu-item index="2">
              <i class="el-icon-menu"></i>
              <span slot="title">我的订单</span>
            </el-menu-item>
            <el-menu-item index="3">
              <i class="el-icon-menu"></i>
              <span slot="title">讲座报名</span>
            </el-menu-item>
            <el-menu-item index="4">
              <i class="el-icon-menu"></i>
              <span slot="title">我的志愿</span>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="right">
        <router-view> </router-view>
      </div>
    </div>
  </div>
</template>
<script>
import {getStudentDetail} from "@/api/personalCenter.js";
export default {
  data () {
    return {
      active: "1",
      user: {},
    };
  },
  mounted () {
    let student1 = sessionStorage.getItem("student") || {}
    let student = JSON.parse(student1)
    this.userId = student.userId || ""
    console.log(this.userId,'this.userId');
    // this.handleSelect(this.active)
    this.getStudentDetail(this.userId)
  },
  methods: {
    getStudentDetail(userId){
      getStudentDetail({userId:userId}).then(res=>{
          if(res.code == 1){
            this.user = res.data || {}
          }
      })
    },
    handleSelect (key) {
      if (key == 1) {
        this.$router.push({path: "/personalCenter/personalInformation",query:{userId:this.userId}});
      }
      if (key == 2) {
        this.$router.push({path: "/personalCenter/myOrder"});
      }
      if (key == 3) {
        this.$router.push({path: "/personalCenter/lectureRegistration"});
      }
      if (key == 4) {
        this.$router.push({path: "/personalCenter/myvolunteer",query:{studentId:this.user.studentId}});
      }
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-menu-item{
  font-size: 16px!important;
}
/deep/.el-menu-item.is-active {
    color: #1AB394!important;
}
/deep/.el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background-color: #fff;
    color: #1AB394!important;
}
.bigbox {  
  // height: 100%;
  overflow: auto;
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  .content {
    box-sizing: border-box;
    min-height: 706px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .left {
      width: 250px;
      .headerimg{
        width: 100%;
        .block{
          width: 120px;
          height: 120px;
          margin: 0 auto;
          border-radius: 60px;
          overflow: hidden;
        }
      }
      .name {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        font-size: 18px;
        color: #333333;
      }
      .menu{
        width: 120px;
        margin: 0 auto;
      }
    }
    .right{
      width: 950px;
    }
  }
}
</style>
