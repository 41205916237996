import axios from '@/util/request'
// 个人中心

// /com/had-login/shopp-order/getShoppOrderByUserPage
// 订单列表 管理app通过当前用户查询
export const getShoppOrderByUserPage = (data) => {
    return axios({
      url: '/com/had-login/shopp-order/getShoppOrderByUserPage',
      method: 'post',
      data
    })
}

//讲座报名
// /com/had-login/lecture-info/getSignUpLectureInfoPage
// app讲座信息管理查询列表-包含当前登录用户是否报名字段
export const getSignUpLectureInfoPage = (data) => {
  return axios({
    url: '/com/had-login/lecture-info/getSignUpLectureInfoPage',
    method: 'post',
    data
  })
}

// 个人信息
// /com/had-login/mobile/user-info/getStudentDetail
// 查询个人资料
export const getStudentDetail = (params) => {
  return axios({
    url: '/com/had-login/mobile/user-info/getStudentDetail',
    method: 'get',
    params
  })
}

// /com/had-login/mobile/user-info/saveStudentInfo
// 保存个人资料
export const saveStudentInfo = (data) => {
  return axios({
    url: '/com/had-login/mobile/user-info/saveStudentInfo',
    method: 'post',
    data
  })
}

// /com/had-login/college/school-info/page
// 获取分页列表
export const schoolpage = (data) => {
  return axios({
    url: '/com/had-login/college/school-info/page',
    method: 'post',
    data
  })
}